.iaptools {
  display: flex;
  padding: 20px;
  gap: 20px;

  .iaptoolsContainer {
    flex: 2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: gray;

      .title {
        margin: auto;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .bottom {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;

      .title {
        font-weight: 500;
        color: gray;
      }

      .desc {
        font-weight: 300;
        font-size: 12px;
        color: gray;
        text-align: center;
      }

      .summary {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

      }
    }
  }

  input[type="file"] {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 10px;
    color: gray;
  }

  button {
    background-color: #4CAF50;
    /* Green background */
    border: none;
    color: white;
    /* White text */
    padding: 10px 20px;
    /* Some padding */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    /* Same font-size as your description text */
    margin: 10px 2px;
    /* A little bit of margin for spacing */
    cursor: pointer;
    /* Cursor pointer to indicate it's clickable */
    border-radius: 5px;
    /* Rounded corners for the button */
  }

  button:hover {
    background-color: #45a049;
    /* Darker shade of green on hover */
  }

  button:disabled {
    background-color: #cccccc;
    /* Light gray background */
    color: #666666;
    /* Darker gray text */
    cursor: not-allowed;
    /* Show a 'not allowed' cursor when hovered */
    opacity: 0.5;
    /* Make the button appear faded */
  }

  .linkButton {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    font-size: 12px;
  }

  .contentContainer {
    display: flex;
    align-items: flex-start;
    /* Align the tops of the checkboxes and table */
    justify-content: center;
    /* Center the children within the content container */
    width: 100%;
  }

  .checkboxContainer {
    display: flex;
    flex-direction: column;
    /* Stack the children vertically */
    align-items: flex-start;
    /* Align items to the start of the container */
    margin-right: 20px;
    /* Space between checkboxes and the table */
    padding: 10px;
  }

  /* Ensure label and checkbox are styled to allow vertical stacking */
  .checkboxContainer label {
    display: block;
    /* Each label takes up the full width of its container */
    margin-bottom: 5px;
    /* Space between each checkbox */
  }

  .resultsContainer {
    flex: 3;
    /* Allows the results container to take up more space than the checkbox container */
    width: 80%;
    /* Adjust based on your needs */
  }

  .lastProcessedDate {
    margin-top: 10px;
    /* Space above the date text */
    text-align: center;
    /* Center the date text */
    /* Add other styles as needed */
    display: flex;
    align-items: center;
  }

  table {
    width: 80%;
    /* Make table take up 80% of container width */
    margin: 0 auto;
    /* Center the table horizontally */
    border-collapse: collapse;
    /* Collapse borders */
  }

  th,
  td {
    text-align: center;
    /* Center table cell content */
    padding: 8px;
    /* Add some padding for spacing */
    border-bottom: 1px solid #ddd;
    /* Add a bottom border to each cell */
  }

}


#processingProgress {
  width: 80%;
  /* Full width of its container */
  // height: 20px;
  /* Adjust height as needed */
  // border-radius: 5px;
  /* Optional: rounded corners */
  margin: auto;
}

.progressContainer {
  display: flex;
  flex-direction: column;
  /* Stack children vertically */
  align-items: center;
  /* Center children horizontally */
  width: 100%;
  /* Take full width to ensure centering takes effect */
}

.progressText {
  text-align: center;
  /* Ensure text is centered, useful if it breaks into multiple lines */
  margin-top: 10px;
  /* Space between progress bar and text */
  /* Other styling as needed */
}

.warning-icon {
  font-size: 18px;
  margin-right: 5px;
  color: orange;
}